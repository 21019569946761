import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Device from "../components/media/Device";
import PageTitle from "../components/parts/PageTitile";
import PankuzuList from "../components/parts/PankuzuList";
import Witems from "../components/parts/Witems";
import { Seo } from "../components/seo";
import { wagashiItemData } from "../data/wagashiItemData";

const Okashi = ({ location }) => {
  const options = [
    {
      label: "今月",
      value: new Date().getMonth() + 1,
    },
    {
      label: "1月",
      value: 1,
    },
    {
      label: "2月",
      value: 2,
    },
    {
      label: "3月",
      value: 3,
    },
    {
      label: "4月",
      value: 4,
    },
    {
      label: "5月",
      value: 5,
    },
    {
      label: "6月",
      value: 6,
    },
    {
      label: "7月",
      value: 7,
    },
    {
      label: "8月",
      value: 8,
    },
    {
      label: "9月",
      value: 9,
    },
    {
      label: "10月",
      value: 10,
    },
    {
      label: "11月",
      value: 11,
    },
    {
      label: "12月",
      value: 12,
    },
  ];
  const initValue = new Date().getMonth() + 1;
  //State
  const [wagashiItemState, setWagashiItemState] = useState(
    wagashiItemData.filter(
      (ob) => ob.month === initValue && ob.display === true
    )
  );

  console.log(wagashiItemData);
  //Function

  const onChangeHandler = (e) => {
    setWagashiItemState(
      wagashiItemData.filter(
        (ob) => ob.month === +e.target.value && ob.display === true
      )
    );
  };
  return (
    <>
      <Layout>
        <PageTitle title="和菓子" subTitle="Wagashi" />
        <PankuzuList title="和菓子一覧" location={location} />

        <StyledDiv>
          <select
            className="select"
            name="month"
            id="month-select"
            onChange={onChangeHandler}
          >
            {options.map((option, index) => (
              <option key={option.value + index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </StyledDiv>

        <StyledWagashi>
          <Witems data={wagashiItemState} />
        </StyledWagashi>
      </Layout>
    </>
  );
};
const StyledDiv = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 3rem;
  @media ${Device.tablet} {
    margin-bottom: 3rem;
    max-width: var(--max-width);
  }
  .select {
    font-size: 1.6rem;
    appearance: none;
    width: 20rem;
    padding: 1rem 1.5rem;
    padding-right: 1em;
    border: none;
    background-color: transparent;
    text-indent: 0.01px;
    cursor: pointer;
    display: inline-block;
    border-bottom: 1px solid var(--sub-color);
  }
  option {
    background-color: var(--white-color);
  }
`;
const StyledWagashi = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: var(--max-width);
`;

export default Okashi;

export const Head = () => <Seo title="商品一覧"></Seo>;
